/* eslint-disable no-nested-ternary */
import App from 'next/app'
import React from 'react'

// eslint-disable-next-line import/namespace, import/default, import/no-named-as-default, import/no-named-as-default-member
import NiceModal from '@ebay/nice-modal-react'
import { createGlobalStyle } from 'styled-components'
import rhizTheme, { ThemeProvider } from '@tofu/shared/theme'
import 'react-datepicker/dist/react-datepicker.css'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { InlineScripts } from '@tofu/account/ui/molecules/inline-scripts'

import { getShopConstants } from '@tofu/shared/data-access/shop-constants'
import { client } from '@tofu/shared/utils/axios-client'
import { getGlobalAccountSettings } from '@tofu/account/data-access/prismic'
import PageLayout from '@tofu/apps/account/components/layout/page'
import { ActionsProvider } from '@tofu/apps/account/providers/actions'
import { CartProvider } from '@tofu/apps/account/providers/cart'
import { FeedbackProvider } from '@tofu/apps/account/providers/feedback'
import { ModalProvider } from '@tofu/apps/account/providers/modal'
import { LoaderProvider } from '@tofu/apps/account/providers/loader'
import { ReactQueryProvider } from '@tofu/shared/providers/react-query-provider'
import { SessionProvider } from '@tofu/apps/account/providers/session'
import { StoreProvider } from '@tofu/apps/account/providers/store'
import { ConstantsProvider } from '@tofu/shared/providers/constants-provider'
import { GlobalAccountContentProvider } from '@tofu/account/providers/global-account-content-provider'
import datepicker from '@tofu/apps/account/styles/datepicker'
import global from '@tofu/apps/account/styles/global'
import { logger } from '@tofu/shared/utils/sentry'
import Error from './_error'

const GlobalStyle = createGlobalStyle`
  ${global}
  ${datepicker}
`

export default class MyApp extends App {
  constructor() {
    super()
    this.state = {
      errorEventId: undefined,
      hasError: false
    }
  }

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    const globalAccountSettings = await getGlobalAccountSettings()
    const constants = await getShopConstants(client)

    return { pageProps, globalAccountSettings, constants }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined
    }
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = logger(error, { errorInfo })
    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId })
  }

  render() {
    const { Component, pageProps, globalAccountSettings, constants } =
      this.props
    const { hasError } = this.state

    return (
      <ReactQueryProvider pageProps={pageProps}>
        <GlobalAccountContentProvider
          defaultPromoBanner={globalAccountSettings?.default_promo_banner}
        >
          <SessionProvider>
            <FeedbackProvider>
              <StoreProvider>
                <ModalProvider>
                  <LoaderProvider>
                    <ActionsProvider>
                      <CartProvider>
                        <GlobalStyle />
                        <InlineScripts />
                        <ThemeProvider theme={rhizTheme}>
                          <ConstantsProvider constants={constants}>
                            <NiceModal.Provider>
                              <PageLayout>
                                {hasError ? (
                                  <Error />
                                ) : (
                                  <Component {...pageProps} />
                                )}
                              </PageLayout>
                            </NiceModal.Provider>
                          </ConstantsProvider>
                        </ThemeProvider>
                      </CartProvider>
                    </ActionsProvider>
                  </LoaderProvider>
                </ModalProvider>
              </StoreProvider>
            </FeedbackProvider>
          </SessionProvider>
        </GlobalAccountContentProvider>
      </ReactQueryProvider>
    )
  }
}
