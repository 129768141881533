import type { ComponentStyleConfig } from '@chakra-ui/theme'
import type { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Tag: ComponentStyleConfig = {
  baseStyle: ({ theme }: StyleFunctionProps) => ({
    container: {
      ...theme.textStyles['h6']
    }
  }),

  sizes: {
    sm: ({ theme }: StyleFunctionProps) => ({
      container: {
        fontSize: theme.fontSizes['xs']
      }
    })
  },

  variants: {
    outline: ({ theme }: StyleFunctionProps) => ({
      container: {
        bg: 'white',
        padding: '5px 7px',
        boxShadow: 'inset 0 0 0px 1px #F5F5F5', // TODO get this from theme once merged
        borderRadius: theme.radii['full']
      }
    }),
    solid: ({ theme, colorScheme }: StyleFunctionProps) => ({
      container: {
        bg: theme['colors'][colorScheme].main,
        color: theme['colors'][colorScheme].contrastText,
        padding: '5px 7px',
        borderRadius: theme.radii['full']
      }
    }),
    subtle: ({ theme }: StyleFunctionProps) => ({
      container: {
        padding: '5px 7px',
        borderRadius: theme.radii['md']
      }
    }),
    transparent: ({ theme }: StyleFunctionProps) => ({
      container: {
        bg: 'transparent',
        padding: '0',
        borderRadius: theme.radii['md']
      }
    })
  },

  defaultProps: {
    variant: 'outline'
  }
}
