import { List } from '@chakra-ui/react'
import equals from 'lodash/fp/equals'

import { Box } from '@tofu/shared/ui/atoms/box'
import { HStack } from '@tofu/shared/ui/atoms/stack'
import { Container } from '@tofu/shared/ui/atoms/container'
import { Tag } from '@tofu/shared/ui/atoms/tag'

import { DesktopMenuItem } from './desktop-menu-item'
import { TDesktopMenu } from './desktop-menu.types'

export const DesktopMenu: TDesktopMenu = ({
  currentPathname,
  nextDelivery,
  ...boxProps
}) => {
  const isActive = equals(currentPathname)
  const hasNextDelivery = !!nextDelivery?.id
  const nextDeliveryUrl = `/deliveries/${nextDelivery?.id}`

  return (
    <Box
      data-testid='desktop-nav'
      as='nav'
      bg='grey.100'
      {...boxProps}
      whiteSpace='nowrap'
      overflowX='auto'
    >
      <Container maxWidth='1094px'>
        <HStack
          as={List}
          role='navigation'
          justifyContent='space-between'
          bg='grey.100'
        >
          <DesktopMenuItem url='/deliveries' isActive={isActive('/deliveries')}>
            Home
          </DesktopMenuItem>
          {hasNextDelivery && (
            <DesktopMenuItem
              url={nextDeliveryUrl}
              isActive={isActive('/deliveries/[orderId]')}
            >
              Your next delivery
            </DesktopMenuItem>
          )}
          <DesktopMenuItem
            url='/subscriptions'
            isActive={isActive('/subscriptions')}
          >
            Subscription settings
          </DesktopMenuItem>
          <DesktopMenuItem url='/impact' isActive={isActive('/impact')}>
            <Tag
              size='sm'
              variant='solid'
              colorScheme='success'
              sx={{
                padding: '0px 7px',
                marginTop: 1,
                marginRight: 2,
                lineHeight: '100%'
              }}
            >
              New
            </Tag>
            Your impact
          </DesktopMenuItem>
          <DesktopMenuItem url='/details' isActive={isActive('/details')}>
            Personal details
          </DesktopMenuItem>
          <DesktopMenuItem url='/referrals' isActive={isActive('/referrals')}>
            Refer a friend
          </DesktopMenuItem>
        </HStack>
      </Container>
    </Box>
  )
}
